var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card-progress",
    attrs: {
      "padding": 0,
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("销售渠道排行榜")]), _c('div', [_c('Dropdown', {
    staticClass: "sortby",
    on: {
      "on-click": function ($event) {
        _vm.sortBy = $event;
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.sortBy) + " "), _c('Icon', {
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', {
    attrs: {
      "name": "按年统计"
    }
  }, [_vm._v("按年统计")]), _c('DropdownItem', {
    attrs: {
      "name": "按月统计"
    }
  }, [_vm._v("按月统计")]), _c('DropdownItem', {
    attrs: {
      "name": "按周统计"
    }
  }, [_vm._v("按周统计")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "progress-content"
  }, [_c('div', {
    staticClass: "progress-item"
  }, [_c('div', {
    staticClass: "dot",
    staticStyle: {
      "background": "#5b73e8"
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("PC端")]), _c('Progress', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "percent": _vm.percent1,
      "stroke-width": 6,
      "stroke-color": "#5b73e8",
      "hide-info": ""
    }
  })], 1), _c('div', {
    staticClass: "progress-item"
  }, [_c('div', {
    staticClass: "dot",
    staticStyle: {
      "background": "#23b397"
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("iPhone")]), _c('Progress', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "percent": _vm.percent2,
      "stroke-width": 6,
      "stroke-color": "#23b397",
      "hide-info": ""
    }
  })], 1), _c('div', {
    staticClass: "progress-item"
  }, [_c('div', {
    staticClass: "dot",
    staticStyle: {
      "background": "#56c2d6"
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("Android")]), _c('Progress', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "percent": _vm.percent3,
      "stroke-width": 6,
      "stroke-color": "#56c2d6",
      "hide-info": ""
    }
  })], 1), _c('div', {
    staticClass: "progress-item"
  }, [_c('div', {
    staticClass: "dot",
    staticStyle: {
      "background": "#f8cc6b"
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("小程序")]), _c('Progress', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "percent": _vm.percent4,
      "stroke-width": 6,
      "stroke-color": "#f8cc6b",
      "hide-info": ""
    }
  })], 1), _c('div', {
    staticClass: "progress-item"
  }, [_c('div', {
    staticClass: "dot",
    staticStyle: {
      "background": "#f0643b"
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("平板端")]), _c('Progress', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "percent": _vm.percent5,
      "stroke-width": 6,
      "stroke-color": "#f0643b",
      "hide-info": ""
    }
  })], 1)])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };