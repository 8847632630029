export default {
  name: "card-button",
  components: {},
  props: {},
  data() {
    return {
      percent1: 0,
      percent2: 0,
      percent3: 0,
      percent4: 0,
      percent5: 0,
      sortBy: "按年统计"
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.percent1 = 72;
        this.percent2 = 39;
        this.percent3 = 61;
        this.percent4 = 52;
        this.percent5 = 28;
      }, 200);
    }
  },
  mounted() {
    this.init();
  }
};